import { render, staticRenderFns } from "./NonSurgicalWeightLossSubCategories.vue?vue&type=template&id=5e3ed509&scoped=true&"
import script from "./NonSurgicalWeightLossSubCategories.vue?vue&type=script&lang=js&"
export * from "./NonSurgicalWeightLossSubCategories.vue?vue&type=script&lang=js&"
import style0 from "./NonSurgicalWeightLossSubCategories.vue?vue&type=style&index=0&id=5e3ed509&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e3ed509",
  null
  
)

export default component.exports